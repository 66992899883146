import * as ACTIONS from '../actionTypes';
import { enqueueSnackbar } from './notificationActions';
import { getFutureAppointments } from "./appointmentsActions";
import axios from "axios";
import config from "../../config";

export function hideModal() {
    return function(dispatch) {
        dispatch({
            type: ACTIONS.HIDE_MODAL,
            payload: ""
        });
    }
}

export function setActiveProfile(profile){
    return function(dispatch) {
        dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.SET_ACTIVE_PROFILE,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/auth/set-profile', { profile }, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                        window.location.reload();
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getPromos() {
    return function(dispatch) {
        //dispatch(setLoading(true));
        dispatch( {
            type: ACTIONS.GET_PROMOS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/promos/list', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        //dispatch(getBanners());
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getBanners(type) {
    return function(dispatch) {
        dispatch( {
            type: ACTIONS.GET_BANNERS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + `/promos/banners/${type }`, {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function handleError(err, dispatch, flag = false) {

    let message = "";

    if(err.response){
        if(err.response.status === 401) {
            message = err.response.data;
            dispatch(redirect("/login"));
        }
        if(err.response.status === 403) {
            message = err.response.data;
        }
        if(err.response.status === 404) {
            message = "Страница недоступна.";
            dispatch(redirect("/404"));
        }
        else {
            message = err.response.data;
        }
    }
    else {
        console.log(err);
        message = "Ошибка при подключении к серверу. Попробуйте еще раз позднее.";
        //dispatch(redirect("/login"));
    }

    dispatch(enqueueSnackbar({
        message: message,
        options: config.snackOptions.error
    }));

    dispatch(setLoading(flag));
}

export function redirect(path) {
    return function (dispatch) {
        dispatch({
            type: ACTIONS.REDIRECT,
            payload: path
        });
    }
}

export function setCheckAuth(flag) {
    return function (dispatch) {
        dispatch({
            type: ACTIONS.DROP_CHECK_AUTH,
            payload: flag
        });
    }
}

export function setUserChanges(data) {
    return function (dispatch) {
        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.SET_USER_CHANGES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/settings/set/', data, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(enqueueSnackbar({
                            message: "Настройки успешно сохранены",
                            options: config.snackOptions.success
                        }));
                        dispatch(setLoading(false));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function checkAuth() {
    return function (dispatch) {
        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.CHECK_AUTH,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/auth/check/', {}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(getFutureAppointments());
                        dispatch(getPromos());

                        //send signal to native app about successful login (in case we use it)
                        if (window.ReactNativeWebView){
                            window.ReactNativeWebView.postMessage("LOGIN_SUCCESSFUL");
                        }
                    })
                    .catch(function (err) {
                        //handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function resetPassword(guid) {
    return function (dispatch) {
        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.RESET_PASSWORD,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/auth/resetPassword/', {guid}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                        dispatch(enqueueSnackbar({
                            message: res.data,
                            options: config.snackOptions.success
                        }));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });
    }
}

export function getFile(fileId) {
    return function (dispatch) {
        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.GET_FILE,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/files/get/', {fileId}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function sendRegCode(phone){
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.SEND_REG_CODE,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/reg/sendsms', { phone }, config.axiosOptions)
                    .then((res) => {

                        //console.log(res.data);

                        dispatch(setLoading(false));

                        dispatch(enqueueSnackbar({
                            message: res.data,
                            options: config.snackOptions.success
                        }));

                        resolve(res.data);
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function checkRegCode(phone, code){
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.CHECK_REG_CODE,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/reg/checksms', { phone, code }, config.axiosOptions)
                    .then((res) => {

                        //console.log(res.data);

                        dispatch(setLoading(false));

                        dispatch(enqueueSnackbar({
                            message: res.data,
                            options: config.snackOptions.success
                        }));

                        resolve(res.data);
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function submitRegistration(data){
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.SUBMIT_REGISTRATION,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/reg/submit', data, config.axiosOptions)
                    .then((res) => {

                        //console.log(res.data);

                        dispatch(setLoading(false));

                        dispatch(enqueueSnackbar({
                            message: res.data,
                            options: config.snackOptions.success
                        }));

                        resolve(res.data);

                        dispatch(redirect("/login?p=" + data.tel));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function getBalance(){
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.GET_BALANCE,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/settings/balans', { }, config.axiosOptions)
                    .then((res) => {

                        //console.log(res.data);

                        dispatch(setLoading(false));

                        if(res.data['Сумма'] !== undefined){
                            resolve(res.data['Сумма']);
                        }
                        else throw "Не удалось получить инфомацию баланс счета";
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function getLinkToAddBalance(data) {
    return function(dispatch) {
        dispatch(setLoading(true));
        //dispatch(showModal(false));
        dispatch( {
            type: ACTIONS.GET_PAYMENT_URL,
            payload: new Promise((resolve, reject) => {

                data.source = "";

                if(window.ReactNativeWebView) {
                    if( localStorage.os ) data.source = localStorage.os;
                    else data.source = "mobile-app";
                }
                else data.source = "web-lk";

                axios.post(config.api + '/payments/geturltoaddbalance', data, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(showModal(true));
                        dispatch(setLoading(false));
                    } )
                    .catch((err) => {
                        handleError(err, dispatch);
                        dispatch(setLoading(false));
                        dispatch(showModal(false));
                    });
            })
        });
    }
}

export function getBonusCode(){
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.GET_BONUS_CODE,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/promos/code', { getCode: true }, config.axiosOptions)
                    .then((res) => {
                        if(res.data && res.data['Код']) resolve(res.data['Код']);
                        else dispatch(enqueueSnackbar({
                            message: "Не удалось получить код. Попробуйте еще раз.",
                            options: config.snackOptions.error
                        }));
                        dispatch(setLoading(false));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function getBonusHistory(){
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.GET_BONUS_HISTORY,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/promos/history', { getCode: true }, config.axiosOptions)
                    .then((res) => {
                        if(res.data['ИсторияБонусов']) resolve(res.data['ИсторияБонусов']);
                        else dispatch(enqueueSnackbar({
                            message: "Не удалось получить историю бонусов. Попробуйте еще раз.",
                            options: config.snackOptions.error
                        }));
                        dispatch(setLoading(false));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function getProfiles(phone) {
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.GET_PROFILES,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/auth/profiles', {phone}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        /*
                        if(res.data.length === 0){
                            dispatch(enqueueSnackbar({
                                message: "Для указанного телефона не найдено ни одного профиля.",
                                options: config.snackOptions.error
                            }));
                        }
                         */
                        dispatch(setLoading(false));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export const showDoctorPage = (doc) => ({
    type: ACTIONS.SHOW_DOCTOR_PAGE,
    payload: doc
});

export function login(profile, password) {
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.LOGIN,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/auth/login', {profile, password}, config.axiosOptions)
                    .then((res) => {
                        resolve(res.data);
                        dispatch(setLoading(false));

                        //send signal to native app about successful login (in case we use it)
                        if (window.ReactNativeWebView){
                            window.ReactNativeWebView.postMessage("LOGIN_SUCCESSFUL");
                        }
                    })
                    .catch((err) => {
                        handleError(err,dispatch);
                        reject(err);
                    });
            })
        })
    }
}

export function logout(userId) {
    return function (dispatch) {

        dispatch(setLoading(true));

        dispatch({
            type: ACTIONS.LOGOUT,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/auth/logout', {}, config.axiosOptions)
                    .then((res) => {

                        //send signal to native app about successful login (in case we use it)
                        if (window.ReactNativeWebView){
                            let dataToSend = {
                                event: "LOGOUT",
                                guid: userId
                            };
                            window.ReactNativeWebView.postMessage(JSON.stringify(dataToSend));
                        }

                        resolve(res.data);
                        dispatch(setLoading(false));
                    })
                    .catch(function (err) {
                        handleError(err, dispatch);
                        reject(err);
                    });
            })
        });

        sessionStorage.removeItem("welcomeShown");
    }
}

export const setLoading = (flag = true) => ({
    type: ACTIONS.LOADING,
    payload: flag
});

export const showModal = (flag = true) => ({
    type: ACTIONS.SHOW_MODAL,
    payload: flag
});

export const showImgModal = (url) => ({
    type: ACTIONS.SHOW_IMG_MODAL,
    payload: `<img class="img-fluid" src="${url}" />`
});

export const setBackground = (bg) => ({
    type: ACTIONS.SET_BACKGROUND,
    payload: bg
});


export function getLibs() {
    return function(dispatch) {
        dispatch({
            type: ACTIONS.LOADING,
            payload: true
        });
        dispatch( {
            type: ACTIONS.GET_LIBS,
            payload: new Promise((resolve, reject) => {
                axios.post(config.api + '/lib.get/', {})
                    .then((res) => {
                        resolve(res.data);
                    } )
                    .catch(function (err) {
                        dispatch(enqueueSnackbar({
                            message: "Network or server error",
                            options: config.snackOptions.error
                        }));
                        reject(err);
                    });
            })
        });
    }
}